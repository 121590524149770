import {faCircle, faExclamationCircle, faLink, faList, faPlus, faUmbrella} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Link, MenuItem, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import {groupBy, uniqBy} from "lodash";
import pluralize from "pluralize";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import Moment from "react-moment";
import {PDFObject} from "react-pdfobject";
import {useQueryState} from "use-location-state";
import {textToHTML} from "../../../../js/common/utils";
import HtmlRender from "../../../../js/components/HtmlRender";
import {useSelectIds} from "../../../../js/hooks";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import insuranceAcordForms from "../../../components/forms/choices/insuranceAcordForms.json";
import insuranceCertHolders from "../../../components/forms/choices/insuranceCertHolders.json";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorError} from "../../../theme/colors";
import {COIExpirationDateTableCell} from "../../Insurance/COIComponents";
import {COIUpdateDialogForm} from "../../Insurance/COIForms";
import {AdditionalInsuredForm, CertificateHoldersForm, PolicyDialogForm} from "./ProjectInsuranceForms";
import {InsuranceCompanyPaperItem, InsurancePolicyPaperItem} from "./ProjectInsurancePaperItems";
import {Currency} from "../../../components/Accounting";

const ProjectInsurance = (props) => {
  const {project, ...rest} = props;

  const blockUI = useBlockUI();

  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [deleteOneConfirmationIsOpen, setDeleteOneConfirmationIsOpen] = React.useState(false);
  const [refreshPoliciesDialogIsOpen, setRefreshPoliciesDialogIsOpen] = React.useState(false);
  const [refreshAllPoliciesDialogIsOpen, setRefreshAllPoliciesDialogIsOpen] = React.useState(false);
  const [editPolicyDialogIsOpen, setEditPolicyDialogIsOpen] = React.useState(false);
  const [createPolicyDialogIsOpen, setCreatePolicyDialogIsOpen] = React.useState(false);
  const [certificateHoldersCreateDialogIsOpen, setCertificateHoldersCreateDialogIsOpen] = React.useState(false);
  const [certificateHoldersEditDialogIsOpen, setCertificateHoldersEditDialogIsOpen] = React.useState(false);
  const [deleteCertificateHolderConfirmationDialog, setDeleteCertificateHolderConfirmationDialog] =
    React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const [selectedTab, setSelectedTab] = useQueryState("tab", "policies");
  const [initialFocusField, setInitialFocusField] = React.useState(null);
  const [activeCOI, setActiveCOI] = React.useState({} as any);
  const [showUpdateCOIDialog, setShowUpdateCOIDialog] = React.useState(false);

  const novoClasses = makeNovoClasses();

  const [coiFilterOptions, setCOIFilterOption, clearCOIFilterOption, clearAllCOIFilterOptions] = useFilterOptions(
    ["Search", "Latest", "Acord Form", "Cert Holder"],
    {Latest: {value: "True", label: "True"}}
  );
  const coiFilterParams = {
    q: coiFilterOptions.Search.value,
    latest: coiFilterOptions.Latest.value,
    acord_form: coiFilterOptions["Acord Form"].value,
    cert_holder: coiFilterOptions["Cert Holder"].value,
  };

  const {
    query: insuranceQuery,
    delete: deletePolicy,
    create: createPolicy,
    update: updatePolicy,
    rpc: policyRPC,
  } = useSentinelListAPI(`projects/${project.id}/insurance/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const {update: updateProject, query: projectQuery} = useSentinelDetailAPI(`projects/${project.id}/`, {
    initialData: {},
    // keepPreviousData: true,
  });

  const projectData = projectQuery.data;

  const {
    query: certificateHoldersQuery,
    update: updateCertificateHolders,
    create: createCertificateHolder,
    delete: deleteCertificateHolder,
  } = useSentinelListAPI(`projects/${project.id}/insurance/certificate-holders/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const certificateHolders = certificateHoldersQuery.data.results;

  const {
    query: coiQuery,
    update: updateCOI,
    delete: deleteCOI,
    rpc: coiRPC,
  } = useSentinelListAPI(
    `projects/${project.id}/insurance/cois/?ordering=insured_company_name,coi_date&${qs.stringify(coiFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      // keepPreviousData: true,
    },
    {
      idProp: "uuid",
    }
  );
  const cois = coiQuery.data.results;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      companiesMissingInsurance: [],
      subcontractsMissingCOI: [],
    },
  });

  const insuranceList = insuranceQuery.data.results;

  const insuredCompanyLookup = groupBy(insuranceList, (insurance) => insurance.company.name);
  const insuredCompanies = uniqBy(
    insuranceList.map((insuredCompany) => insuredCompany.company),
    "id"
  );

  const uninsuredCompanies = pageDataQuery?.data?.companiesMissingInsurance;
  const subcontractsMissingCOI = pageDataQuery?.data?.subcontractsMissingCOI;

  const allPolicyIds = insuranceList.map((policy) => policy.id);

  const {
    selectedIds: selectedPolicyIds,
    addSelectedId: addSelectedPolicyId,
    addSelectedIds: addSelectedPolicyIds,
    removeSelectedId: removeSelectedPolicyId,
    removeSelectedIds: removeSelectedPolicyIds,
    addAllSelectedIds: addAllSelectedPolicyIds,
    removeAllSelectedIds: removeAllSelectedPolicyIds,
    allIdsSelected: allPolicyIdsSelected,
  } = useSelectIds(allPolicyIds);

  return (
    <>
      <Helmet title={`${project.display} - Project Insurance`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Project Insurance</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Policies
                  </Box>
                }
                value="policies"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Cert Holders/Additional
                  </Box>
                }
                value="additional"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    COIs
                    <TabCountChip isLoading={!coiQuery.isFetchedAfterMount} count={coiQuery.data.count} />
                  </Box>
                }
                value="cois"
              />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="policies">
            <>
              <PaperPanel.TabHeader isLoading={insuranceQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <PaperItem.SelectedCheckbox
                    label={"Select All"}
                    onChange={(event, value) => {
                      if (value) {
                        addAllSelectedPolicyIds();
                      } else {
                        removeAllSelectedPolicyIds();
                      }
                    }}
                    indeterminate={Boolean(
                      !allPolicyIdsSelected && selectedPolicyIds.size < allPolicyIds.length && selectedPolicyIds.size
                    )}
                    checked={allPolicyIdsSelected}
                  />
                  <FontAwesomeIcon icon={faUmbrella} fixedWidth />
                  Project Insurance
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.DeleteButton
                      disabled={selectedPolicyIds.size < 1}
                      onClick={() => {
                        setDeleteConfirmationIsOpen(true);
                      }}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.DropdownMenu
                      startIcon={<FontAwesomeIcon icon={faPlus} />}
                      title="Create Policies"
                    >
                      <MenuItem key="all_policies" onClick={() => setRefreshAllPoliciesDialogIsOpen(true)}>
                        All Policies
                      </MenuItem>
                      {uninsuredCompanies.length > 0 && (
                        <>
                          <MenuItem divider key="policy_divider" />
                          {uninsuredCompanies.map((uninsuredCompany) => (
                            <MenuItem
                              key={uninsuredCompany.id}
                              onClick={() => {
                                setRefreshPoliciesDialogIsOpen(true);
                                setActiveItem(uninsuredCompany);
                              }}
                            >
                              {uninsuredCompany.name}
                            </MenuItem>
                          ))}
                        </>
                      )}
                    </PaperPanel.Header.DropdownMenu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButton href={`/reports2/projects/${project.id}/insurance/`} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => {
                        insuranceQuery.refetch();
                        projectQuery.refetch();
                      }}
                      isFetching={insuranceQuery.isFetching || projectQuery.isFetching}
                    />
                    <Box mr={-1} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mt={-2} mb={-3}>
                {insuredCompanies.map((insured: any) => {
                  return (
                    <React.Fragment key={insured.id}>
                      <InsuranceCompanyPaperItem
                        company={insured}
                        isFetching={insuranceQuery.isFetching}
                        onRefreshPolicies={() => {
                          setRefreshPoliciesDialogIsOpen(true);
                          setActiveItem(insured);
                        }}
                        onCreatePolicy={() => {
                          setActiveItem({company: {id: insured.id}});
                          setCreatePolicyDialogIsOpen(true);
                        }}
                      />

                      {insuredCompanyLookup[insured.name].map((policy) => (
                        <InsurancePolicyPaperItem
                          highlight={policy.id === activeItem.id}
                          key={policy.id}
                          policy={policy}
                          policyIsSelected={selectedPolicyIds.has(policy.id)}
                          onChangePolicySelected={(event, value) => {
                            if (value) {
                              addSelectedPolicyId(policy.id, event.nativeEvent.shiftKey);
                            } else {
                              removeSelectedPolicyId(policy.id);
                            }
                          }}
                          onEditPolicy={() => {
                            setActiveItem(policy);
                            setEditPolicyDialogIsOpen(true);
                          }}
                          onDeletePolicy={() => {
                            setActiveItem(policy);
                            setDeleteOneConfirmationIsOpen(true);
                          }}
                        />
                      ))}
                    </React.Fragment>
                  );
                })}
              </Box>

              {/* <h1>PageData</h1>
              <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
            </>
            {/* </Box> */}
          </TabPanel>

          <TabPanel value="additional">
            <>
              {/* <Box mx={-3} mt={-4} mb={-3}> */}
              {/* <PaperPanel> */}
              <PaperPanel.TabHeader isLoading={certificateHoldersQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faList} /> Certificate Holders
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton onClick={() => setCertificateHoldersCreateDialogIsOpen(true)}>
                      Add Certificate Holder
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButton href={`/reports2/projects/${project.id}/insurance/requirements/`} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => {
                        insuranceQuery.refetch();
                        projectQuery.refetch();
                      }}
                      isFetching={insuranceQuery.isFetching || projectQuery.isFetching}
                    />
                    <Box mr={-1} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <PaperPanel.Body sectionBody>
                {certificateHolders.map((holder) => {
                  return (
                    <PaperItem key={holder.id}>
                      <PaperItem.Body>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            {holder.name}
                          </Grid>{" "}
                          <Grid item xs={12} sm={6}>
                            <HtmlRender html={textToHTML(holder.address)} />
                          </Grid>
                        </Grid>
                      </PaperItem.Body>
                      <PaperItem.RightHover>
                        <PaperItem.RightHover.IconButton
                          icon={EditIcon}
                          title="Edit"
                          onClick={() => {
                            setActiveItem(holder);
                            setCertificateHoldersEditDialogIsOpen(true);
                          }}
                        />
                        <PaperItem.RightHover.IconButton
                          icon={DeleteIcon}
                          title="Delete"
                          onClick={() => {
                            setActiveItem(holder);
                            setDeleteCertificateHolderConfirmationDialog(true);
                          }}
                        />
                      </PaperItem.RightHover>
                    </PaperItem>
                  );
                })}
              </PaperPanel.Body>
              {/* </PaperPanel> */}
              <Box my={1} />

              <PaperPanel.TabHeader sectionHeader isLoading={certificateHoldersQuery.isFetching}>
                <PaperPanel.Header.Title>Additional Insured</PaperPanel.Header.Title>
              </PaperPanel.TabHeader>
              <PaperPanel.Body p={1} maxHeight={550} overflow="auto" sectionBody>
                <AdditionalInsuredForm
                  onSubmit={(values, form) => {
                    blockUI.blockUI("Saving...");
                    updateProject
                      .mutateAsync(values)
                      .then(() => {
                        blockUI.unblockUI();
                      })
                      .catch(() => blockUI.unblockUI());
                  }}
                  initialValues={projectData}
                />
              </PaperPanel.Body>
              {/* </Box> */}
            </>
          </TabPanel>
          <TabPanel value="cois">
            <PaperPanel.TabHeader isLoading={coiQuery.isFetching}>
              <PaperPanel.Header.Title>Project COIs</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      coiQuery.refetch();
                    }}
                    isFetching={coiQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <Box mx={-3} mt={-2}>
              <PaperPanel.Alert severity="info">
                Email COI's to{" "}
                <Link href="mailto:certificates@novoconstruction.com" underline="always">
                  certificates@novoconstruction.com
                </Link>
                . COI attachments emailed to the address will be automatically processed every hour on the hour.
              </PaperPanel.Alert>

              <PaperPanel.Toolbar p={1}>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <FilterSearch
                      label="Search"
                      value={coiFilterOptions.Search.value}
                      name="search"
                      onChange={(value) => {
                        setCOIFilterOption("Search", value, value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <FilterSelect
                      // native
                      label="Latest"
                      name="latest"
                      options={[
                        {label: "True", value: "True"},
                        {label: "False", value: "False"},
                      ]}
                      value={coiFilterOptions["Latest"].value}
                      onChange={(value, label) => {
                        setCOIFilterOption("Latest", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <FilterSelect
                      // native
                      label="Acord Form"
                      name="acord_form"
                      options={insuranceAcordForms}
                      value={coiFilterOptions["Acord Form"].value}
                      onChange={(value, label) => {
                        setCOIFilterOption("Acord Form", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={2} xs={12}>
                    <FilterSelect
                      // native
                      label="Cert Holder"
                      name="cert_holder"
                      options={insuranceCertHolders}
                      value={coiFilterOptions["Cert Holder"].value}
                      onChange={(value, label) => {
                        setCOIFilterOption("Cert Holder", value, label);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <FilterOptionChips
                      filterOptions={coiFilterOptions}
                      onDelete={(key) => {
                        clearCOIFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        clearAllCOIFilterOptions();
                      }}
                    />
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>

              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["insurance_in_compliance"]}
                getCellProps={(cell) => {
                  if (["timberline_job_number", "coi_date", "min_expiration_date"].includes(cell.column.id)) {
                    return {
                      style: {
                        whiteSpace: "nowrap",
                      },
                    };
                  }
                }}
                columns={React.useMemo(
                  () => [
                    {
                      Header: "Insured",
                      accessor: "insured_company_name",
                      Cell: ({value, row}) => (
                        <Box display="flex" alignItems="center">
                          <Box mr={1}>
                            <Link href={row.original.url} target="_blank">
                              <FontAwesomeIcon icon={faLink} />
                            </Link>
                          </Box>
                          <Box>
                            <Link
                              style={{cursor: "pointer"}}
                              underline="always"
                              onClick={() => {
                                setActiveCOI(row.original);
                                setShowUpdateCOIDialog(true);
                              }}
                            >
                              {value ? value : "No Company Found"}
                            </Link>
                          </Box>
                          {!row.original?.insured_fuzzy_company && (
                            <Box ml={1}>
                              <Tooltip title={`${row.original?.insured_company_name} - Not found`}>
                                <FontAwesomeIcon icon={faCircle} color={colorError} />
                              </Tooltip>
                            </Box>
                          )}
                        </Box>
                      ),
                    },
                    {
                      Header: "ACORD Form",
                      accessor: "acord_form_display",
                    },
                    {
                      Header: "Cert Holder",
                      accessor: "cert_holder_display",
                    },
                    {
                      Header: "COI Date",
                      accessor: "coi_date",
                    },
                    {
                      Header: "Ex Date(s)",
                      accessor: "min_expiration_date",
                      Cell: ({value, row}) => <COIExpirationDateTableCell value={value} row={row} />,
                    },
                    {
                      Header: "Processed",
                      accessor: "ai_processed",
                      Cell: ({value, row}) => <>{value && <Moment calendar withTitle date={value} />}</>,
                    },
                    {
                      Header: "",
                      accessor: "insurance_in_compliance",
                      // sortType: "basic",
                      Cell: ({value, row}) => {
                        if (value) {
                          return null;
                        }
                        return (
                          <Tooltip
                            title={
                              <>
                                {row.original.insurance_compliance_issues.map((issue) => (
                                  <div key={issue}>{issue}</div>
                                ))}
                              </>
                            }
                          >
                            <FontAwesomeIcon icon={faExclamationCircle} color={colorError} fixedWidth />
                          </Tooltip>
                        );
                      },
                    },
                  ],
                  []
                )}
                initialState={{
                  sortBy: [
                    {
                      id: "insured_company_name",
                      desc: false,
                    },
                  ],
                }}
                data={cois}
              />

              {/* <pre>{JSON.stringify(cois, null, 2)}</pre> */}
            </Box>

            <PaperPanel.TabHeader sectionHeader isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Subcontracts Missing COI</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      pageDataQuery.refetch();
                    }}
                    isFetching={pageDataQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <Box mx={-3} mb={-3}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["current_value"]}
                getCellProps={(cell) => {
                  if (["timberline_job_number", "coi_date", "min_expiration_date"].includes(cell.column.id)) {
                    return {
                      style: {
                        whiteSpace: "nowrap",
                      },
                    };
                  }
                }}
                columns={React.useMemo(
                  () => [
                    {
                      Header: "Subcontract",
                      accessor: "display",
                      Cell: ({value, row}) => (
                        <MuiNavLink underline="always" href={row.original.url}>
                          {value}
                        </MuiNavLink>
                      ),
                    },
                    {
                      Header: "To Company",
                      accessor: "to_company.name",
                    },
                    {
                      Header: "Current Value",
                      accessor: "current_value",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                    },
                  ],
                  []
                )}
                initialState={{
                  sortBy: [
                    {
                      id: "display",
                      desc: false,
                    },
                  ],
                }}
                data={subcontractsMissingCOI}
              />
              {/* <pre>{JSON.stringify(subcontractsMissingCOI, null, 2)}</pre> */}
            </Box>
          </TabPanel>
        </PaperPanel>

        <ConfirmationDialog
          isOpen={deleteConfirmationIsOpen}
          onDeny={() => setDeleteConfirmationIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Deleting...");
            policyRPC
              .mutateAsync({method: "DELETE", action: "delete", data: {ids: Array.from(selectedPolicyIds)}})
              .then(() => {
                removeAllSelectedPolicyIds();
                setDeleteConfirmationIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Do you want to delete {selectedPolicyIds.size} selected {pluralize("policy", selectedPolicyIds.size)}?
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={deleteOneConfirmationIsOpen}
          onDeny={() => setDeleteOneConfirmationIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Deleting...");
            deletePolicy
              .mutateAsync(activeItem.id)
              .then(() => {
                setEditPolicyDialogIsOpen(false);
                setDeleteOneConfirmationIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Do you want to delete this policy?
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={refreshPoliciesDialogIsOpen}
          onDeny={() => setRefreshPoliciesDialogIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Updating...");
            policyRPC
              .mutateAsync({
                action: "update-or-create-insurance-policies-for-company",
                method: "POST",
                data: {company_id: activeItem.id},
              })
              .then(() => Promise.all([insuranceQuery.refetch(), pageDataQuery.refetch()]))
              .then(() => {
                setRefreshPoliciesDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Are you sure add/update all policies for {activeItem?.name}? Policy data specified at the company level will
          override the current data.
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={refreshAllPoliciesDialogIsOpen}
          onDeny={() => setRefreshAllPoliciesDialogIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Updating...");
            policyRPC
              .mutateAsync({
                action: "action-update-or-create-all-insurance-policies",
                method: "POST",
                data: {project_id: project.id},
              })
              .then(() => Promise.all([insuranceQuery.refetch(), pageDataQuery.refetch()]))
              .then(() => {
                setRefreshAllPoliciesDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Are you sure add/update all policies for all companies? Policy data specified at the company level will
          override the current data.
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={deleteCertificateHolderConfirmationDialog}
          onDeny={() => setDeleteCertificateHolderConfirmationDialog(false)}
          onApprove={() => {
            blockUI.blockUI("Deleting...");
            deleteCertificateHolder
              .mutateAsync(activeItem.id)
              .then(() => {
                setDeleteCertificateHolderConfirmationDialog(false);
                blockUI.unblockUI();
              })
              .then(() => blockUI.unblockUI());
          }}
          title="Are you sure?"
        >
          Are you sure you want to remove {activeItem?.name} as a certificate holder? This can not be undone.
        </ConfirmationDialog>

        <PolicyDialogForm
          isOpen={editPolicyDialogIsOpen}
          handleClose={() => {
            setEditPolicyDialogIsOpen(false);
            setActiveItem({});
            setInitialFocusField(null);
          }}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            updatePolicy
              .mutateAsync(values)
              .then(() => {
                setEditPolicyDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .then(() => blockUI.unblockUI());
          }}
          onDeletePolicy={() => {
            setDeleteOneConfirmationIsOpen(true);
          }}
          initialValues={activeItem}
          activeItem={activeItem}
          items={insuredCompanyLookup[activeItem?.company?.name]}
          setActiveItem={setActiveItem}
          beforePrevNext={(values) => {
            // blockUI.blockUI("Updating...");
            return updatePolicy.mutateAsync(values).then(() => {
              // blockUI.unblockUI();
            });
          }}
          // afterPrevNext={() => {}}
          initialFocusField={initialFocusField}
          setInitialFocusField={setInitialFocusField}
        />

        <PolicyDialogForm
          isOpen={createPolicyDialogIsOpen}
          handleClose={() => {
            setCreatePolicyDialogIsOpen(false);
            setActiveItem({});
          }}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            createPolicy
              .mutateAsync(values)
              .then(() => {
                setCreatePolicyDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .then(() => blockUI.unblockUI());
          }}
          isNew
          initialValues={activeItem}
        />

        <CertificateHoldersForm
          isOpen={certificateHoldersCreateDialogIsOpen}
          handleClose={() => setCertificateHoldersCreateDialogIsOpen(false)}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            createCertificateHolder
              .mutateAsync(values)
              .then(() => {
                setCertificateHoldersCreateDialogIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          isNew
        />
        <CertificateHoldersForm
          isOpen={certificateHoldersEditDialogIsOpen}
          handleClose={() => setCertificateHoldersEditDialogIsOpen(false)}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            updateCertificateHolders
              .mutateAsync(values)
              .then(() => {
                setCertificateHoldersEditDialogIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          initialValues={activeItem}
          onDelete={() => {
            setCertificateHoldersEditDialogIsOpen(false);
            setActiveItem(activeItem);
            setDeleteCertificateHolderConfirmationDialog(true);
          }}
        />
        <COIUpdateDialogForm
          isOpen={showUpdateCOIDialog}
          handleClose={() => {
            setShowUpdateCOIDialog(false);
          }}
          initialValues={activeCOI}
          onSubmit={(values) => {
            values.expiration_dates_json = values.expiration_dates_json.filter((item) => item !== null);
            blockUI.blockUI("Saving...");
            updateCOI.mutateAsync(values).then((newCloseoutRequest) => {
              blockUI.unblockUI();
              setShowUpdateCOIDialog(false);
              coiQuery.refetch();
            });
          }}
          // onDelete={() => {
          //   blockUI.blockUI("Deleting...");
          //   deleteCOI.mutateAsync(activeCOI.uuid).then(() => {
          //     blockUI.unblockUI();
          //     setShowUpdateCOIDialog(false);
          //     coiQuery.refetch();
          //   });
          // }}
        >
          <PDFObject
            url={`/api/v1/insurance/cois/${activeCOI.uuid}/pdf/`}
            pdfOpenParams={{pagemode: "thumbs", view: "FitH"}}
            containerProps={{style: {width: "100%", height: "60vh"}}}
          />
        </COIUpdateDialogForm>
      </TabContext>
      <LegacyUILink href={`/projects/${project.id}/insurance/`} mt={2} />
    </>
  );
};

export default ProjectInsurance;
